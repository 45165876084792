import {
    Abstract
} from './Abstract';

export class CustomerClassification extends Abstract {
    constructor(session) {
        super('comercial/CustomerClassification', session);
        this.name = null;
        this.key_name = null;
    }
}
